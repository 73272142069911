import React from 'react'
import { graphql, Link } from 'gatsby'
import { startCase } from 'lodash'
import Layout from '../components/Layout'
import Container from '../components/Container'
import Pagination from '../components/Pagination'
import SEO from '../components/SEO'
import Header from '../components/SermonPage/Header'
import BreadCrumb from '../components/SermonPage/BreadCrumb'
import { SUNDAY_SERVICE_BASE, SERIES_BASE } from '../routes'
import { SUNDAY_SERVICE_TITLE, WEEKDAY_SERVICE_TITLE, SERIES_TITLE, SERIES_DATA_TYPE_SUNDAY, SERIES_DATA_TYPE_WEEKDAY } from '../constants'

// todo: import elsewhere
import styled from '@emotion/styled'

const SeriesLink = styled(Link)`
  color: ${props => props.theme.colors.green};
  font-size: 1rem;
  font-weight: 600;
  text-decoration: none;
`

const Series = ({ data, pageContext }) => {
    const { basePath, headerTitle } = pageContext
    const isSunday = (basePath === SUNDAY_SERVICE_BASE);
    const seriesDataType = isSunday ? SERIES_DATA_TYPE_SUNDAY : SERIES_DATA_TYPE_WEEKDAY;
    const title = isSunday ? SUNDAY_SERVICE_TITLE : WEEKDAY_SERVICE_TITLE;
    const seriesList = data.allContentfulSeries.edges;
    const seriesListFiltered = seriesList.filter(series => (series.node[seriesDataType] !== null))
    const routes = [{ slug: basePath, label: title }, { slug: `${basePath}/${SERIES_BASE}/`, label: SERIES_TITLE}];
 
    return (
        <Layout>
            <SEO title={startCase(basePath)} />
            <Header title={headerTitle} height={'20vh'} />
            <Container>
                <BreadCrumb routes={routes} />
                {seriesListFiltered
                  .map(series => (
                    <div key={series.node.slug}>
                      <SeriesLink 
                        to={`${basePath}/${SERIES_BASE}/${series.node.slug}`} 
                        state={{routes: routes}}
                      >
                        {series.node.name} ({series.node[seriesDataType].length})
                      </SeriesLink>
                      {series.node[seriesDataType].map(sermon => (
                        <div key={sermon.id}>
                          {sermon.title} / 
                          <SeriesLink 
                            to={`${basePath}/${sermon.dateSlug}`} 
                            state={{routes: routes.concat([{ slug: `${basePath}/${SERIES_BASE}/${series.node.slug}`, label: series.node.name}])}}
                          >
                            {sermon.dateSlug}
                          </SeriesLink>
                        </div>
                      ))}
                    </div>))
                }
                
            </Container>
            <Pagination context={pageContext} />
        </Layout>
    )
}

export const query = graphql`
  query {
    allContentfulSeries {
      edges {
        node {
          slug
          name
          sermon_sunday {
            id
            title
            dateSlug: date(formatString: "YYYY-MM-DD")
          }
          sermon_weekday {
            id
            title
            dateSlug: date(formatString: "YYYY-MM-DD")
          }
        }
      }
    }
  }
`

export default Series
