import React from 'react'
import styled from '@emotion/styled'
import { Link } from 'gatsby'

const ListContainer = styled.div`
	/* border: 1px dotted #ccc; */
	display: flex;
	margin: -2rem auto 2rem;
	width: 100%;
	max-width: 59rem;
	@media screen and (max-width: ${props => props.theme.responsive.medium}) {
		padding: initial;
		margin-bottom: 0.5rem;
	}
`

const List = styled.ul`
	/* border: 1px dotted #ccc; */display: flex;
	display: flex;
	justify-content: left;
	align-items: center;
	flex-direction: row;
	flex-wrap: wrap;
	width: 100%;
	padding-left: 0.25rem;
	@media screen and (max-width: ${props => props.theme.responsive.medium}) {
		margin-bottom: 1rem;
		padding-left: initial;
	}
`

const Item = styled.li`
	display: flex;
	flex-flow: row;
	color: ${props => props.theme.colors.text};
	font-size: 1em;
	line-height: 1.25em;
	text-decoration: none;
	@media screen and (max-width: ${props => props.theme.responsive.medium}) {
		font-size: 14px;
		line-height: 1.25rem;
	}
	a {
		color: ${props => props.theme.colors.green};
		text-decoration: none;
		font-weight: 600;
		transition: 0.2s;
		&:hover {
			background: ${props => props.theme.colors.lightGrey};
		}
		@media screen and (max-width: ${props => props.theme.responsive.medium}) {
			font-size: 14px;
		}
	}
`

const BreadCrumb = props => {
	const size = props.routes.length;

	return (
		<ListContainer>
			<List>
				{props.routes.map((route, index) => (
					<Item key={index}>
						{(index !== 0) && <div>&nbsp;&#62;&nbsp;</div>}
						{(size === 1 || index === size - 1)
							? <div>{route.label}</div>
							: <Link to={route.slug}>{route.label}</Link>
						}
					</Item>
				))}
			</List>
		</ListContainer>
	)
}

export default BreadCrumb
