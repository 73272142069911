import React from 'react'
import styled from '@emotion/styled'
import IconSundaySermon from '../../../static/images/sundaysermon.svg'
import IconWeekdaySermon from '../../../static/images/weekdaysermon.svg'
import { SUNDAY_SERVICE_TITLE } from '../../constants'

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
`
const Top = styled.section`
  height: 12em;
  background-color: ${props => props.theme.colors.text};
  @media screen and (max-width: ${props => props.theme.responsive.small}) {
    height: 10em;
  }
`
const Bar = styled.section`
  height: 10px;
  background-color: ${props => props.sunday ? props.theme.colors.green : props.theme.colors.yellow};
  @media screen and (max-width: ${props => props.theme.responsive.small}) {
    height: 8px;
  }
`
const List = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 2em 0;
  height: 100%;
  width: 100%;
  background-color: ${props => props.theme.colors.text};
  @media screen and (max-width: ${props => props.theme.responsive.small}) {
    padding: 1.5em 0;
  }
`
const Item = styled.li`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 3rem;
  @media screen and (min-width: ${props => props.theme.responsive.small}) {
    width: auto;
  }
`
const Title = styled.span`
  color: ${props => props.theme.colors.background};
  letter-spacing: 0;
  line-height: 3.75rem;
  font-size: 2.5rem;
  text-align: center;
  @media screen and (max-width: ${props => props.theme.responsive.small}) {
    font-size: 2rem;
  }
`
const StyledIconSundaySermon = styled(IconSundaySermon)`
  width: 3rem;
  height: 3rem;
  @media screen and (max-width: ${props => props.theme.responsive.small}) {
    width: 2.75rem;
    height: 2.75rem;
  }
`
const StyledIconWeekdaySermon = styled(IconWeekdaySermon)`
  width: 4rem;
  height: 4rem;
  @media screen and (max-width: ${props => props.theme.responsive.small}) {
    width: 3.5rem;
    height: 3.5rem;
  }
`

const Header = props => (
  <Wrapper height={props.height}>
    <Top>
      <List>
        <Item>
          {props.title === SUNDAY_SERVICE_TITLE 
            ? <StyledIconSundaySermon /> 
            : <StyledIconWeekdaySermon /> 
          }
        </Item>
        <Item>
          <Title>{props.title}</Title>
        </Item>
      </List>
    </Top>
    <Bar sunday={props.title === SUNDAY_SERVICE_TITLE}/>
  </Wrapper>
)

export default Header
